<template>
  <div v-animate-css="{
        classes: 'fadeIn',
        duration: 1000,
      }">
    <div class="text-center mb-5">
      <h4>{{ question.order }}. {{ question.title }}</h4>
      <p>{{ question.description }}</p>
    </div>
    <div class=" container d-flex align-items-center justify-content-center flex-column ">
      <img src="../../assets/icons/icono_pregunta2.svg" alt="">
      <div class="d-flex  align-items-center h-25">
        <button @click="handleCounter('minus')" class="btn btn-success">-</button>
        <div class="border px-5 py-1 mx-2">
          <p class="m-0">{{ result }}</p>
        </div>
        <button @click="handleCounter('sum')" class="btn btn-success">+</button>
      </div>
    </div>
    <div class=" buttons row mt-5 ">
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handlePrev">
          {{ $store.getters.getPrevText }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" :disabled="handleValidation" @click="handleNext">
          {{$store.getters.getNextText}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import {counterQuestionInitialData} from "../../utils/questionsInitialData";
import Vue from 'vue';
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);


export default {
  name: "Counter",
  data() {
    return {
      result: 0,
      question: counterQuestionInitialData,
    }
  },
  computed: {
    handleValidation() {
      return this.result < 1;
    }
  },
  watch: {
    '$store.state.currentQuestion': function () {
      this.question = store.state.currentQuestion;
      if (store.state.currentQuestion.result.answer !== null) {
        this.result = store.state.currentQuestion.result.answer
      }
    }
  },
  mounted() {
  },
  methods: {
    async handleNext() {
      const data = {
        idQuestion: store.getters.getCurrentQuestion._id,
        orderQuestion: store.getters.getCurrentQuestion.order,
        section: 'valorization',
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        answer: this.result
      }
      this.$emit('sendQuestion', data);
    },
    handlePrev() {
      this.$emit('goPrev');
    },
    handleCounter(type) {
      if (type === 'sum') {
        return this.result++
      } else {
        if (this.result < 1) {
          return this.result = 0
        } else {
          return this.result--
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">

.icon {
  fill: #23AF4D;
}

.controls-button {
  &:hover {
    .icon {
      fill: white;
    }
  }
}

.buttons {
  button {
    margin: 0 0.5rem;

    &:disabled {
      filter: grayscale(100%);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}
</style>
